import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { AllModules } from "../../variables/index";
import ModuleItem from "./elements/ModuleItem";
import SectionHeader from "./elements/SectionHeader";
import { setSelected, setBuilderModuleId, setBotModule, setElements, setBuilderIndex, setBuilderMode } from "../../actions";

import { updatePage } from "../../actions";
import Dashboard from './Dashboard';
import server from '../../api/server';
import { Helmet } from "react-helmet";
import { faExclamationTriangle, faHammer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import buildExistingCommand from './commandbuilder/buildExistingCommand';
import history from '../../history';
import BotPanelBanner from './BotPanelBanner';
import PremiumRequiredModal from './elements/PremiumRequiredModal';

class Modules extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: "trigger_description",
            premiumModal: false,
            premiumMessage: "",
            name: "",
            id: "",
            showImportModal: false,
            description: "",
            options: [
            ],
            actions: [
            ],
            compressed_actions: null,
            permissions: {
                allowed_roles: [{ name: '@everyone', id: 'everyone' }],
                banned_roles: [],
                banned_channels: [],
                banned_users: [],
                required_permissions: []
            },
            cooldown: {
                type: "disabled",
                interval: 1,
                interval_type: "minutes"
            },
            error: "",
            ephemeral: false,
            handleErrors: true,
            errors: [],
            disabled: false,
        };
    }

    componentDidMount() {

    }

    checkDepreciation(module) {
        var depreciated = true;
        if (module.name == "Reaction Roles") {
            if (this.props.bot?.commands?.reaction_roles?.enabled == true) {
                depreciated = false;
            }
        } else if (module.name == "Levels") {
            if (this.props.bot?.commands?.levels?.enabled == true) {
                depreciated = false;
            }
        } else if (module.name == "Twitch") {
            if (this.props.bot?.commands?.twitch?.length > 0) {
                depreciated = false;
            }
        } else if (module.name == "YouTube") {
            if (this.props.bot?.commands?.youtube?.tracking?.length > 0) {
                depreciated = false;
            }
        } else if (module.name == "Statistics Channels") {
            if (this.props.bot?.commands?.statsChannels?.enabled) {
                depreciated = false;
            }
        } else if (module.name == "Timed Messages") {
            // bot.commands.messenger.enabled
            if (this.props.bot?.commands?.messenger?.enabled) {
                depreciated = false;
            }
        } else if (module.name == "Active Developer Badge") {
            // bot.commands.activeDevBadge.enabled
            if (this.props.bot?.commands?.activeDevBadge?.enabled) {
                depreciated = false;
            }
        } else if (module.name == "Status") {
            // bot.commands.activeDevBadge.enabled
            if (this.props.bot?.status || this.props.bot.commands?.status?.enabled === true) {
                depreciated = false;
            }
        }

        return depreciated;
    }
    // col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12

    renderModules = () => {
        const categories = [];
        var customModules = [...this.props.modules];
        // Filter out beta modules if beta is false
        if (this.props.beta === false) {
            customModules = customModules.filter(module => module.beta !== true);
        }

        var featured = [];
        var favourited = [];
        var newModules = [];

        var favModules = this.props.user.favModules;
        AllModules.forEach(category => {
            const categoryModules = [];
            category.modules.forEach(categoryModule => {
                if ((categoryModule.name != "Text Commands" || this.props.bot.commands.autoresponder.length > 0) && (categoryModule.beta != true || (this.props.beta == true))) {
                    if (categoryModule.depreciated == true && this.checkDepreciation(categoryModule)) {
                        return;
                    }
                    var moduleItem = <div className="module-row-item">
                        <ModuleItem featured={categoryModule.featured} link={categoryModule.link} updated={categoryModule.updated} new={categoryModule.new} title={categoryModule.name} icon={categoryModule.icon} description={categoryModule.description} premium={categoryModule.premium}></ModuleItem>
                    </div>;
                    if (categoryModule.featured) {
                        featured.push(moduleItem);
                    } else if (favModules.includes(categoryModule.name)) {
                        favourited.push(moduleItem);
                    } else if (categoryModule.new) {
                        newModules.push(moduleItem);
                    } else {
                        categoryModules.push(
                            moduleItem
                        );
                    }

                }

            });
            var index = 0;
            while (index < customModules.length) {
                var customModule = customModules[index];
                if (customModule.hidden && this.props.alpha != true) {
                    customModules.splice(index, 1);
                    index++;
                    continue;
                }
                var moduleItem = <div className="module-row-item">
                    <ModuleItem custom={true} id={customModule.id} updated={customModule.updated} new={customModule.new} title={customModule.name} icon={customModule.img} description={customModule.description} premium={customModule.premium}></ModuleItem>
                </div>;
                if (customModule.featured) {
                    featured.push(moduleItem);
                    customModules.splice(index, 1);
                } else if (favModules.includes(customModule.id)) {
                    favourited.push(moduleItem);
                    customModules.splice(index, 1);
                } else if (customModule.new) {
                    newModules.push(moduleItem);
                    customModules.splice(index, 1);
                }
                else if (customModule.category == category.name) {
                    // Put at start of array;
                    categoryModules.unshift(
                        <div className="module-row-item">
                            <ModuleItem custom={true} id={customModule.id} updated={customModule.updated} new={customModule.new} title={customModule.name} icon={customModule.img} description={customModule.description} premium={customModule.premium}></ModuleItem>
                        </div>
                    );

                    customModules.splice(index, 1);
                } else {
                    index++;
                }
            }

            // customModules.forEach((customModule, index) => {

            //     var moduleItem = <div className="module-row-item">
            //         <ModuleItem custom={true} id={customModule.id} updated={customModule.updated} new={customModule.new} title={customModule.name} icon={customModule.img} description={customModule.description} premium={customModule.premium}></ModuleItem>
            //     </div>;
            //     if (customModule.featured) {
            //         featured.push(moduleItem);
            //         customModules.splice(index, 1);
            //     } else if (favModules.includes(customModule.id)) {
            //         favourited.push(moduleItem);
            //         customModules.splice(index, 1);
            //     } else if (customModule.new) {
            //         newModules.push(moduleItem);
            //         customModules.splice(index, 1);
            //     }
            //     else if (customModule.category == category.name) {
            //         // Put at start of array;
            //         categoryModules.unshift(
            //             <div className="module-row-item">
            //                 <ModuleItem custom={true} id={customModule.id} updated={customModule.updated} new={customModule.new} title={customModule.name} icon={customModule.img} description={customModule.description} premium={customModule.premium}></ModuleItem>
            //             </div>
            //         );

            //         customModules.splice(index, 1);
            //     }
            // });

            if (categoryModules.length > 0) {
                categories.push(
                    <div style={{ marginTop: "25px" }}>
                        <SectionHeader title={category.name} pretitle="Modules"></SectionHeader>
                        <div className="module-row mt-2">
                            {categoryModules}
                        </div>
                    </div>
                );
            }
        });

        // Check for modules that are not in a category;
        // Create that category and add any other modules with the same category to it.
        // Group remaining by category
        var groupedModules = customModules.reduce((r, a) => {
            r[a.category] = [...r[a.category] || [], a];
            return r;
        }, {});

        Object.keys(groupedModules).forEach((category, index) => {
            const categoryModules = [];
            groupedModules[category].forEach((customModule, index) => {
                categoryModules.push(
                    <div className="module-row-item">
                        <ModuleItem custom={true} id={customModule.id} updated={customModule.updated} new={customModule.new} title={customModule.name} icon={customModule.img} description={customModule.description} premium={customModule.premium}></ModuleItem>
                    </div>
                );
            });

            categories.unshift(
                <div style={{ marginTop: "25px" }}>
                    <SectionHeader title={category} pretitle="Modules"></SectionHeader>
                    <div className="module-row mt-2">
                        {categoryModules}
                    </div>
                </div>
            );
        });

        var featuredandfavourited = [...featured, ...favourited, ...newModules];
        if (featured.length > 0) {
            categories.unshift(
                <div style={{ marginTop: "25px" }}>
                    <SectionHeader title="Featured & Favourited" pretitle="Modules"></SectionHeader>
                    <div className="module-row mt-2">
                        {featuredandfavourited}
                    </div>
                </div>
            );
        }




        return categories;
    };


    checkActiveCommands = (mode) => {
        if (mode == "event") {
            var count = 0;
            this.props.custom_events.events.forEach((event) => {
                if (event.enabled == undefined || event.enabled == true) {
                    count += 1;
                }
            });
            console.log(count, 'COUNT HERE');
            if (count >= 3) {
                return false;
            }
        } else {
            var count = 0;
            this.props.slash.commands.forEach((command) => {
                if (command.enabled == undefined || command.enabled == true) {
                    count += 1;
                }
            });

            if ((count >= 8 && this.props.bv != 2) || (count >= 3 && this.props.bv == 2)) {
                return false;
            }
        }

        return true;
    };


    commandBuilder = async (mode) => {
        if (this.props.premium == false && !this.checkActiveCommands(mode)) {
            if (mode == "event") {
                return this.setState({ premiumModal: true, premiumMessage: "You have reached your custom event limit. Either delete or disable some events or upgrade to Premium for unlimited custom events." });
            } else {
                return this.setState({ premiumModal: true, premiumMessage: "You have reached your custom command limit. Either delete or disable some commands or upgrade to Premium for unlimited custom commands." });
            }
        }
        var data = {
            title: this.state.name,
            description: this.state.description,
            data: {
                ephemeral: false,
                permissions: {
                    allowed_roles: [{ name: '@everyone', id: 'everyone' }],
                    banned_roles: [],
                    banned_channels: [],
                    banned_users: [],
                    required_permissions: []
                },
                cooldown: this.state.cooldown,
                handleErrors: this.state.handleErrors,
                errors: this.state.errors,
            }
        };

        var components = {
            Guild: true,
            Channel: true,
            User: true,
            Member: true,
            Interaction: true,
        };
        if (mode == "event") {
            data = {
                event: true,
                enabled: true,
                data: {
                    name: "",
                    type: "",
                }
            };

            components = {
                Guild: true,
                Channel: true,
            };
        }

        var elements = [
            {
                id: 'root',
                type: 'root',

                data: data,
                // target:"3",
                draggable: false,
                position: { x: 250, y: 250 },
            },
        ];
        if (mode != "event") {

            var errorElements = await buildExistingCommand({
                options: [],
                actions: [{

                    nodeID: "action_3_6d1a_9853",
                    embed: {
                        color: "#FF0000",
                        description: "{error_reason}",
                        title: ":x: {error_message}",
                        footer: "{error_block}"
                    },
                    emojis: [],
                    target: { reply: true },
                    type: "embed"
                }
                ]
            }, 'command', true);
            elements.push(...errorElements);
        } else {
            elements.push({
                id: "error_handler",
                type: "error",
                position: {
                    x: 850,
                    y: 0
                },
                draggable: false,
                data: {
                    node_options: {
                        title: "Error Handler",
                        description: "Handle errors that occur during the event execution",
                        icon: faExclamationTriangle
                    },
                    data: {}
                }
            });
        }
        // console.log(elements, 'ELEMENTS ', mode);
        this.props.setElements(elements);
        this.props.setBuilderMode({
            mode: mode == "event" ? "event" : "command",
            components: components
        });
        this.props.setSelected(null);
        this.props.setBuilderIndex(null);

        history.push("/dashboard/builder");

    };

    render() {
        return (
            <>
                <Helmet>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                </Helmet>
                <Dashboard location={this.props.location}>
                    <BotPanelBanner />
                    {this.state.premiumModal && <PremiumRequiredModal open={this.state.premiumModal} close={() => { this.setState({ premiumModal: false }); }} premiumMessage={this.state.premiumMessage} />}
                    {this.props.premium != true && this.props.user.partner_referred_by != null && this.props.user.partner_referred_by != undefined && this.props.user.partner_claimed != true ?
                        <div className='partner-banner'>
                            <div style={{ marginRight: "15px" }}>
                                <h3>Claim 7 days of free premium</h3>
                                <p>A BotGhost partner has invited you to BotGhost and gifted you 7 days of free premium. Click the Upgrade button to upgrade this bot to premium.</p>

                            </div>
                            <div style={{ marginLeft: "auto" }}>
                                <button onClick={async (e) => {
                                    this.setState({ disabled: true });
                                    var response = await server.post("/dashboard/partnerupgrade", {
                                        bot_id: this.props.bot.id
                                    });

                                    if (response && response.data == true) {
                                        window.location.reload();
                                    }
                                }} disabled={this.state.disabled} className='btn btn-red partner-button'>Upgrade</button>
                            </div>
                        </div> : null}

                    <div>
                        <SectionHeader title="Builders" pretitle="MODULES" />
                        <div className='module-row grid grid-cols-1 md:grid-cols-2 gap-y-5 mt-3 gap-x-5'>
                            <div className='bg-[#2f3136] p-3 rounded-sm'>
                                <div className='custom-command-builder-goto' onClick={(e) => { this.commandBuilder('command'); }} >
                                    <div className={` action-node option-node`}>

                                        <div className="inner" style={{ display: "flex" }}>


                                            <div className="object-icon object-icon-template">

                                                <FontAwesomeIcon icon={faHammer} />
                                            </div>

                                            <div className="object-info object-info-action">
                                                <span className='object-title'>Command Builder</span>
                                                {/* <span className="object-title">{this.state.name != "" ? `/${this.state.name}` : "Command Builder"}</span> */}
                                                <span className="object-description">Click here to start building a custom command.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='bg-[#2f3136] p-3 rounded-sm'>
                                <div className='custom-command-builder-goto' onClick={(e) => { this.commandBuilder('event'); }} >
                                    <div className={` action-node option-node`}>

                                        <div className="inner" style={{ display: "flex" }}>


                                            <div className="object-icon object-icon-template">

                                                <FontAwesomeIcon icon={faHammer} />
                                            </div>

                                            <div className="object-info object-info-action">
                                                <span className='object-title'>Event Builder</span>
                                                {/* <span className="object-title">{this.state.name != "" ? `/${this.state.name}` : "Command Builder"}</span> */}
                                                <span className="object-description">Click here to start building a custom event.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderModules()}
                </Dashboard>
            </>
        );
    }
};

const mapStateToProps = (state) => ({
    premium: state.data.premium,
    bot: state.data.bot,
    beta: state.data.beta ? true : false,
    user: state.data.user,
    modules: state.data.modules,
    alpha: state.data.user.alpha,
    bv: state.data.bv,
    slash: state.data.bot.commands.slash,
    custom_events: state.data.bot.commands.custom_events,
    premium: state.data.premium,
});

export default connect(mapStateToProps, {
    updatePage, setBotModule,
    setBuilderIndex,
    setBuilderModuleId,
    setElements,
    setSelected,
    setBuilderMode
})(Modules);